<template>
  <RewardTypesTable/>
</template>
<script>
import RewardTypesTable from '@/generated/ziqni/views/rewardTypes/RewardTypesTable';

export default {
  components: {
    RewardTypesTable
  },
  props: {}
};
</script>