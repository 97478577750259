<template>
  <!--
    x-vue-csv-supported: false
    x-vue-table-show: true
    fields: [id, spaceName, created, customFields, tags, metadata, name, description, key, constraints, unitOfMeasure, scheduling]
    x-vue-custom-fields: [ customFields ]
    x-vue-tags: [ tags ]
    x-vue-metadata: [ metadata ]
    -->
  <CIDataTable
      :vuex="vuex"
      :settings="settings"
      :route="route"
      model="rewardTypes"
  />
</template>
<script>
  import { rewardTypes } from '@/config/descriptions/rewardTypes';
  import { rewardTypesTexts } from '@/config/pageTexts/rewardTypes.json';

  export default {
    name: 'RewardTypesTable',
    data() {
      return {
        vuex: {
          getter: {
            theme: 'theme/theme',
            data: 'rewardTypes/rewardTypes',
            fields: 'rewardTypes/fields',
            sortableFields: 'rewardTypes/sortableFields',
            pages: 'rewardTypes/pages',
            loading: 'rewardTypes/loading',
            resultCount: 'rewardTypes/resultCount',
            totalRecords: 'rewardTypes/totalRecords',
            originalFields: 'rewardTypes/originalFields',
            searchableAllFields: 'rewardTypes/searchableAllFields',
            success: 'rewardTypes/success',
            message: 'rewardTypes/message',
          },
          actions: {
            fields: 'rewardTypes/handleFields',
            delete: 'rewardTypes/handleDeleteRewardTypes',
            reset: 'rewardTypes/handleClearFilter',
            query: 'rewardTypes/handleGetRewardTypesByQuery',
          },
        },
        settings: {
          describe: { ...rewardTypes },
          page: { ...rewardTypesTexts },
        },
        route: {
          create: 'CreateRewardType',
          edit: 'EditRewardType',
          preview: 'PreviewRewardType',
        },
      };
    },
  };
</script>